import { EntityDependsOnComponentsCard, EntityDependsOnResourcesCard, EntityLayout } from '@backstage/plugin-catalog'
import { useEntity } from '@backstage/plugin-catalog-react'
import { ApiViewerCatalogViewComponent, ApiViewerShouldShowTab } from '@internal/backstage-plugin-api-viewer'
import { VercelCatalogView } from '@internal/backstage-plugin-vercel'
import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { EntityJiraDashboardContent, isJiraDashboardAvailable } from '@axis-backstage/plugin-jira-dashboard';
import { QetaContent } from './qa'
interface DeploymentProperties {
  type: string
  value: string
}
function DeploymentView({ deployment, cicdContent }: { deployment?: DeploymentProperties, cicdContent: React.JSX.Element }) {
  if (!deployment) return cicdContent

  switch (deployment.type) {
    case 'vercel':
      return <VercelCatalogView />
    default:
      return cicdContent
  }
}

export function WebAppEntityPage({ overviewContent, cicdContent, techdocsContent }: { overviewContent: React.JSX.Element, cicdContent: React.JSX.Element, techdocsContent: React.JSX.Element }) {
  const e = useEntity()
  const [deployment, setDeployment] = React.useState<DeploymentProperties | undefined>()
  useEffect(() => {
    if (e.entity.metadata.annotations?.['deployment']) {
      let v = e.entity.metadata.annotations?.['deployment'].split(':')
      setDeployment({
        type: v[0],
        value: v[1]
      })
    }
  }, [e])


  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/ci-cd" title="CI/CD">
        <DeploymentView deployment={deployment} cicdContent={cicdContent} />
      </EntityLayout.Route>

      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityDependsOnComponentsCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityDependsOnResourcesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      {
        e.entity.metadata.annotations?.['deployment'] && (
          <EntityLayout.Route path="/deployment" title="Deployment">

            <VercelCatalogView />

          </EntityLayout.Route>

        )
      }

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>
    </EntityLayout>
  )
}
function test() {
  return <div>
    Cody
  </div>
}
export function MicroServiceEntityPage({ overviewContent, techdocsContent }: { overviewContent: React.JSX.Element, techdocsContent: React.JSX.Element }) {
  const e = useEntity()

  useEffect(() => {
    console.log(e.entity.metadata.annotations)
    if (e.entity.metadata.annotations?.['deployment']) {
      //let v = e.entity.metadata.annotations?.['deployment'].split(':')

    }
  }, [e])


  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/api" title="API" if={ApiViewerShouldShowTab} tabProps={{ fullWidth: false, wrapper: test }}>
        <ApiViewerCatalogViewComponent />
      </EntityLayout.Route>
      <EntityLayout.Route
        if={entity => isJiraDashboardAvailable(entity, 'jira.com')}
        path="/jira-issues"
        title="Issues"
      >
        <EntityJiraDashboardContent />
      </EntityLayout.Route>

      {
        e.entity.metadata.annotations?.['deployment'] && (
          <EntityLayout.Route path="/deployment" title="Deployment">

            <VercelCatalogView />

          </EntityLayout.Route>


        )
      }

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>
      <EntityLayout.Route path="/qa" title="Q&A">

        <QetaContent />

      </EntityLayout.Route>
    </EntityLayout>
  )
}