import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';
export function VercelIcon(props: SvgIconProps) {
    return <SvgIcon {...props} viewBox="0 0 347 347" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g >
            <path d="M172.559 30L339.118 318.492H6L172.559 30Z"
                fill="currentColor"
                width="1em"
                height="1em"
                display="inline-block" />
        </g>
     


    </SvgIcon>
}    