import { CardTab, TabbedCard } from '@backstage/core-components';

import React from 'react';
export function VercelCatalogView(){
    return (
        <TabbedCard title="Abby" >
        
            <CardTab label="Prod">
                <div>prod</div>
            </CardTab>
            <CardTab label="Dev">
                <div>dev</div>
            </CardTab>
            
        </TabbedCard>
    )
}