import { createTheme, lightTheme, genPageTheme } from '@backstage/theme';
import { shapes } from './util';
const vivintLightTheme = createTheme({
  
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#191b1f',
    },
    info:{
      main: '#000000',
      light: '#000000',
      dark: '#000000'
    },
    background: {
      default: '#EFF0EE',
      paper: '#f9f9f8',
    },
    divider:'#bec0c1',
    navigation: {
      background: '#F5F6F4',
      indicator: '#9baba9',
      color: '#191b1f',
      selectedColor: '#9baba9',
      navItem:{
        hoverBackground:'#6B6F62',
      },
      submenu:{
        background:'#DCDFD8',

      }
    },
  },
  defaultPageTheme: 'home',

  pageTheme: {
    home: genPageTheme({colors:['#6B6F62', '#6B6F62'], shape:shapes.none}),
    documentation:genPageTheme({colors:['#81714D','#81714D'], shape:shapes.none}),
    service: genPageTheme({colors:['#007891','#007891'], shape:shapes.none}),
    tableau: genPageTheme({colors:['#000000','#000000'], shape:shapes.none}),
  },
});
export { vivintLightTheme };
