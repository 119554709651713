import React, {  useEffect } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Entity, } from '@backstage/catalog-model';
import StoplightApi from './StoplightApi';
import { gitlabAuthApiRef, useApi } from '@backstage/core-plugin-api'
import { Gitlab } from '@gitbeaker/rest';

export const ApiViewerShouldShowTab = (e: Entity): boolean => {
    return e.metadata.annotations?.['rare/api-reference'] ? true : false
}


export const ApiViewerCatalogViewComponent = () => {
    const [url, setUrl] = React.useState<string|undefined>()
    const e = useEntity();
    console.log(e)
    const gitlab = useApi(gitlabAuthApiRef)
    async function getFile() {

        let token = await gitlab.getAccessToken(['read_repository', 'read_api', 'api'])
        let git = new Gitlab({ oauthToken: token })
        try {

            let r = await git.RepositoryFiles.show('rare-language/rare-ai', 'openapi.yml', 'main')
            console.log(r)
            setUrl(`data:text/yaml;base64,${r.content}`)
            console.log(url)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {

        getFile()
    }, [])
    return <div style={{ background: '#F5F6F4', position: 'absolute', top: 250, left: 225, right: 0, zIndex: 0 }}>
        <StoplightApi url={url}/>
    </div>




};




